<template>
  <b-row>
    <b-col cols="12">
      <!-- Default Colors -->
      <b-card
        title="Default Colors"
        class="background-color"
      >

        <b-card-text class="mb-0">
          We have a series of colors that are used by default. They include:
        </b-card-text>
        <ul class="colors-list list-unstyled mb-0">
          <li>
            <feather-icon
              size="15"
              icon="ChevronsRightIcon"
            />
            <span>IOT System Blue <code>#294563</code></span>
          </li>
          <li>
            <feather-icon
              size="15"
              icon="ChevronsRightIcon"
            />
            <span>IOT System Black <code>#282828</code></span>
          </li>
          <li>
            <feather-icon
              size="15"
              icon="ChevronsRightIcon"
            />
            <span>Writing Yellow <code>#FAA859</code></span>
          </li>
          <li>
            <feather-icon
              size="15"
              icon="ChevronsRightIcon"
            />
            <span>Reading Green <code>#337845</code></span>
          </li>
          <li>
            <feather-icon
              size="15"
              icon="ChevronsRightIcon"
            />
            <span>Litening Blue <code>#33B2C7</code></span>
          </li>
          <li>
            <feather-icon
              size="15"
              icon="ChevronsRightIcon"
            />
            <span>Speaking Pink <code>#C86478</code></span>
          </li>
        </ul>
        <!-- default -->
        <div class="d-flex justify-content-start flex-wrap">
          <colors-card colorCode="#294563" class="bg-system" statisticTitle="System" :isMargin="true" />
          <colors-card colorCode="#282828" class="bg-system-black" statisticTitle="System Black" :isMargin="true" />
          <colors-card colorCode="#FAA859" class="bg-writing" statisticTitle="Writing" :isMargin="true" />
          <colors-card colorCode="#337845" class="bg-reading" statisticTitle="Reading" :isMargin="true" />
          <colors-card colorCode="#33B2C7" class="bg-listening" statisticTitle="Listening" :isMargin="true" />
          <colors-card colorCode="#C86478" class="bg-speaking" statisticTitle="Speaking" :isMargin="false" />
        </div>
        <!--/ default -->
      </b-card>
      <!-- Default Colors -->

      <!-- Gradient color start -->
      <b-card title="Colors Range Details">
        <b-card-text class="mb-0">
          For each color, we have the corresponding color range as below:
        </b-card-text>
        <!-- System  -->
        <h6 class="mt-1">
          System
        </h6>
        <b-card-text class="mb-0">
          Use <code>.bg-{color}.bg-lighten-{1 | 2 | 3 | 4 | 5}</code> classes for lighten background color.
        </b-card-text>
        <div class="d-flex justify-content-start flex-wrap">
          <colors-card colorCode="#294563" class="bg-system" statisticTitle="System" :isMargin="true" />
          <colors-card colorCode="#08304F" class="bg-system bg-darken-2" statisticTitle="System 2" :isMargin="true" />
          <colors-card colorCode="#536B83" class="bg-system bg-darken-3" statisticTitle="System 3" :isMargin="true" />
          <colors-card colorCode="#7E90A2" class="bg-system bg-darken-4" statisticTitle="System 4" :isMargin="true" />
          <colors-card colorCode="#D4DAE0" class="bg-system bg-darken-5" statisticTitle="System 5" :isMargin="false" />        
        </div>
        <!--/ System -->

        <!-- System Black -->
        <h6 class="mt-1">
          System Black
        </h6>
        <b-card-text class="mb-0">
          Use <code>.bg-{color}.bg-lighten-{1 | 2 | 3 | 4 | 5}</code> classes for lighten background color.
        </b-card-text>
        <div class="d-flex justify-content-start flex-wrap">
          <colors-card colorCode="#282828" class="bg-system-black" statisticTitle="System Black" :isMargin="true" />
          <colors-card colorCode="#787878" class="bg-system-black bg-darken-2" statisticTitle="System Black 2" :isMargin="true" />
          <colors-card colorCode="#888888" class="bg-system-black bg-darken-3" statisticTitle="System Black 3" :isMargin="true" />
          <colors-card colorCode="#A0A0A0" class="bg-system-black bg-darken-4" statisticTitle="System Black 4" :isMargin="true" />
          <colors-card colorCode="#F5F5F5" class="bg-system-black bg-darken-5 text-body" statisticTitle="System Black 5" :isMargin="false" />
        </div>
        <!--/ System Black -->

        <!-- Writing colors -->
        <h6 class="mt-1">
          Writing Color
        </h6>
        <b-card-text class="mb-0">
          Use <code>.bg-{color}.bg-darken-{1 | 2 | 3 | 4}</code> classes for darken background color.
        </b-card-text>
        <div class="d-flex justify-content-start flex-wrap">
          <colors-card colorCode="#FAA859" class="bg-writing" statisticTitle="Writing" :isMargin="true" />
          <colors-card colorCode="#BB7F44" class="bg-writing bg-darken-2" statisticTitle="Writing 2" :isMargin="true" />
          <colors-card colorCode="#FABA7B" class="bg-writing bg-darken-3" statisticTitle="Writing 3" :isMargin="true" />
          <colors-card colorCode="#FBCB9C" class="bg-writing bg-darken-4" statisticTitle="Writing 4" :isMargin="true" />
          <colors-card colorCode="#FEEEDE" class="bg-writing bg-darken-5" statisticTitle="Writing 5" :isMargin="false" />
        </div>
        <!--/ Writing colors -->

        <!-- Reading colors -->
        <h6 class="mt-1">
          Reading Color
        </h6>
        <b-card-text class="mb-0">
          Use <code>.bg-{color}.bg-darken-{1 | 2 | 3 | 4}</code> classes for darken background color.
        </b-card-text>
        <div class="d-flex justify-content-start flex-wrap">
          <colors-card colorCode="#337845" class="bg-reading" statisticTitle="Reading" :isMargin="true" />
          <colors-card colorCode="#265A35" class="bg-reading bg-darken-2" statisticTitle="Reading 2" :isMargin="true" />
          <colors-card colorCode="#5B936B" class="bg-reading bg-darken-3" statisticTitle="Reading 3" :isMargin="true" />
          <colors-card colorCode="#84AE90" class="bg-reading bg-darken-4" statisticTitle="Reading 4" :isMargin="true" />
          <colors-card colorCode="#D6E4DA" class="bg-reading bg-darken-5" statisticTitle="Reading 5" :isMargin="false" />
        </div>
        <!--/ Reading colors -->

        <!-- Listening colors -->
        <h6 class="mt-1">
          Listening Color
        </h6>
        <b-card-text class="mb-0">
          Use <code>.bg-{color}.bg-darken-{1 | 2 | 3 | 4}</code> classes for darken background color.
        </b-card-text>
        <div class="d-flex justify-content-start flex-wrap">
          <colors-card colorCode="#33B2C7" class="bg-listening" statisticTitle="Listening" :isMargin="true" />
          <colors-card colorCode="#268695" class="bg-listening bg-darken-2" statisticTitle="Listening 2" :isMargin="true" />
          <colors-card colorCode="#5BC2D2" class="bg-listening bg-darken-3" statisticTitle="Listening 3" :isMargin="true" />
          <colors-card colorCode="#84D1DD" class="bg-listening bg-darken-4" statisticTitle="Listening 4" :isMargin="true" />
          <colors-card colorCode="#D6F0F4" class="bg-listening bg-darken-5" statisticTitle="Listening 5" :isMargin="false" />
        </div>
        <!--/ Listening colors -->

        <!-- Speaking colors -->
        <h6 class="mt-1">
          Speaking Color
        </h6>
        <b-card-text class="mb-0">
          Use <code>.bg-{color}.bg-darken-{1 | 2 | 3 | 4}</code> classes for darken background color.
        </b-card-text>
        <div class="d-flex justify-content-start flex-wrap">
          <colors-card colorCode="#C76378" class="bg-speaking" statisticTitle="Speaking" :isMargin="true" />
          <colors-card colorCode="#954B5A" class="bg-speaking bg-darken-2" statisticTitle="Speaking 2" :isMargin="true" />
          <colors-card colorCode="#D28393" class="bg-speaking bg-darken-3" statisticTitle="Speaking 3" :isMargin="true" />
          <colors-card colorCode="#DDA2AE" class="bg-speaking bg-darken-4" statisticTitle="Speaking 4" :isMargin="true" />
          <colors-card colorCode="#F4E0E4" class="bg-speaking bg-darken-5" statisticTitle="Speaking 5" :isMargin="false" />
        </div>
        <!--/ Speaking colors -->
      </b-card>
    <!--/ Gradient color start -->
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BAlert, BLink, BCardText, BRow, BCol,
} from 'bootstrap-vue'
import { useClipboard } from '@vueuse/core'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import ColorsCard from './ColorsCard.vue'

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BCardText,
    ToastificationContent,
    ColorsCard
  },
  setup() {
    const toast = useToast()
    const { copy } = useClipboard()

    const copyIconName = iconName => {
      copy(iconName)

      toast({
        component: ToastificationContent,
        props: {
          title: 'Color code has been copied',
          icon: 'CopyIcon',
          variant: 'success',
        },
      })
    }

    return {
      copyIconName,
    }
  },
}
</script>

<style lang="scss" scoped>

@import '@core/scss/vue/pages/page-colors.scss';
</style>
<style lang="scss">
@import 'src/@core/scss/base/core/mixins/transitions.scss';
  .color-code, .color-title {
    position: absolute;
  }
  .color-code {
    @include transition(0.2s ease-out);
    opacity: 0;
    transform: translateY(15px);
  }
  .color-title {
    @include transition(0.2s ease-out);
    opacity: 1;
    padding: 15px;
    transform: translateY(0);
  }
  .colors-container {
    &:hover {
      .color-title {
        opacity: 0;
        transform: translateY(-15px);
      }
      .color-code {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
</style>
