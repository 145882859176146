<template>
  <div v-b-tooltip.hover.top="`Click to copy Hex code`" @click="copyColorCode(colorCode)" class="text-center colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center ml-50 my-1 shadow" v-bind:class="[isMargin ? 'mr-1' : '']">
    <div class="color-title">{{statisticTitle}}</div>
    <div class="color-code">{{colorCode}}</div>
  </div>
</template>

<script>
import {
  BFormGroup, BInputGroup, BInputGroupPrepend, BFormInput, BCard, BCardText, VBTooltip,
} from 'bootstrap-vue'
import { useClipboard } from '@vueuse/core'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
  export default {
    props: {
      isMargin: {
        type: Boolean,
        default: true
      },
      statisticTitle: {
        type: String,
        default: '',
      },
      colorCode: {
        type: String,
        default: '',
      },
    },

    directives: {
      'b-tooltip': VBTooltip,
    },
    
    setup() {
      const toast = useToast()
      const { copy } = useClipboard()

      const copyColorCode = colorCode => {
        copy(colorCode)

        toast({
          component: ToastificationContent,
          props: {
            title: 'Color code has been copied',
            icon: 'CopyIcon',
            variant: 'success',
          },
        })
      }

      return {
        copyColorCode,
      }
    },
  }
</script>
